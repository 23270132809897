import React from 'react';
import styled from 'styled-components';

const ProjectPhotoCarousel = ({ photos }) => {
  const [currentPhoto, setCurrentPhoto] = React.useState(0);

  const handleArrowClick = (direction) => {
    if (direction === 'left') {
      setCurrentPhoto((prev) => (prev === 0 ? photos.length - 1 : prev - 1));
    } else {
      setCurrentPhoto((prev) => (prev === photos.length - 1 ? 0 : prev + 1));
    }
  };

  return (
    <PhotoCarousel>
      <button
        className='arrow arrow-left'
        onClick={() => handleArrowClick('left')}
      >
        &#8592;
      </button>
      <img
        src={photos[currentPhoto]}
        alt={`Project ${currentPhoto + 1}`}
      />
      <button
        className='arrow arrow-right'
        onClick={() => handleArrowClick('right')}
      >
        &#8594;
      </button>
    </PhotoCarousel>
  );
};

const PhotoCarousel = styled.div`
  position: relative;
  width: 70%;
  max-width: 550px;
  overflow: hidden;
  margin-top: 20px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 16px;
    /* box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.7); */
  }

  & .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  & .arrow-left {
    left: 10px;
  }

  & .arrow-right {
    right: 10px;
  }
`;

export default ProjectPhotoCarousel;
