import styled from 'styled-components';
import ProjectMainImage from '../../assets/ProjectPageImages/ProjectPageMainImage.png';

export const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: black;
  margin-top: -10vh;
  margin-bottom: 10vh;

  @media (max-width: 1300px) {
    padding-bottom: 5vh;
    height: fit-content;
  }

  @media (max-width: 480px) {
    margin-bottom: 0;
    padding-bottom: 2vh;
  }

  @media screen and (max-height: 800px) {
    height: fit-content;
  }
`;

export const ProjectContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  max-width: 1200px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 100px;
  border-radius: 24px;

  @media (max-width: 730px) {
    padding: 5vh;
  }

  @media (max-width: 480px) {
    margin-bottom: 10vh;
    padding: 0;
  }
`;

export const ProjectBodyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;

  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }
`;

export const ProjectSpecsContainer = styled.div`
  padding-top: 20px;
  padding-right: 48px;

  & div {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 8px;
  }

  & img {
    width: 52px;
    height: 52px;
  }

  & p {
    font-weight: 600;
    font-size: 16px;
    text-align: start;
  }

  @media (max-width: 800px) {
    padding: 20px 0;
  }

  @media (max-width: 480px) {
    & img {
      width: 36px;
      height: 36px;
    }

    & p {
      font-size: 12px;
    }
  }
`;

export const ProjectTitle = styled.h1`
  position: relative;
  width: 100%;
  background-size: cover;
  height: 170px;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
  border-bottom: 4px solid black;

  & div {
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  & h1,
  & h2 {
    color: black;
    font-weight: 800;
  }

  & h1 {
    font-size: 92px;
    padding-bottom: 12px;
  }

  & h2 {
    font-size: 52px;
  }

  @media (max-width: 1300px) {
    & h1 {
      font-size: 64px;
      padding-bottom: 0px;
      margin: 0px;
    }
    & h2 {
      padding-left: 0px;
      font-size: 36px;
      margin: 0px;
    }
  }

  @media (max-width: 480px) {
    align-items: center;
    & h1 {
      font-size: 58px;
    }
    & h2 {
      font-size: 30px;
    }
  }

  @media screen and (max-height: 400px) {
    & h1 {
      font-size: 48px;
    }
    & h2 {
      font-size: 28px;
    }
  }
`;

export const ProjectMainImageContainer = styled.div`
  height: calc(100vh - 60px);
  width: 100%;
  background-image: url(${ProjectMainImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10vh;

  @media screen and (max-width: 730px) {
    background: #e5b13a;
    height: 100px;
    margin-bottom: 15vh;
  }
`;

export const ProjectMainImageContentText = styled.h1`
  margin: 0 0 50px;
  color: white;
  font-size: 140px;
  text-decoration: underline;
  text-decoration-color: #e5b13a;

  @media screen and (max-width: 730px) {
    font-size: 50px;
    margin: 0;
    color: #002145;
    z-index: 1;
  }
`;
