import React, { useState } from 'react';
import NavigationBar from '../NavigationBar';
import Sidebar from '../Sidebar';
import Footer from '../Footer';
import {
  ProjectContainer,
  ProjectContent,
  ProjectTitle,
  ProjectMainImageContainer,
  ProjectMainImageContentText,
  ProjectBodyContainer,
  ProjectSpecsContainer,
} from './ProjectPageStyle';
import { projectsData } from './projectsData';
import ProjectPhotoCarousel from './ProjectPhotoCarousel';

const ProjectPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div>
        <NavigationBar
          isOpen={isOpen}
          toggle={toggle}
          page='our-garage'
        />
        <Sidebar
          isOpen={isOpen}
          toggle={toggle}
        />
        <ProjectMainImageContainer>
          <ProjectMainImageContentText>Projects</ProjectMainImageContentText>
        </ProjectMainImageContainer>

        <div>
          {projectsData.map((project, index) => (
            <ProjectContainer key={index}>
              <ProjectContent>
                <ProjectTitle>
                  <div>
                    <h1>{project.name}</h1>
                  </div>
                  <div>
                    <h2>{project.date}</h2>
                  </div>
                </ProjectTitle>

                <ProjectBodyContainer>
                  <ProjectSpecsContainer>
                    {project.specs.map((text, index) => (
                      <div key={index}>
                        <img
                          src={project.icons[index]}
                          alt={`icon for ${text}`}
                        />
                        <p>{text}</p>
                      </div>
                    ))}
                  </ProjectSpecsContainer>
                  <ProjectPhotoCarousel photos={project.imgs} />
                </ProjectBodyContainer>
              </ProjectContent>
            </ProjectContainer>
          ))}
        </div>

        <Footer />
      </div>
    </>
  );
};

export default ProjectPage;
